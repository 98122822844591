export const _mockQuiz = [
    {id: "qz1" ,
    content: [
        {question: "Какой вариант выберите на этом шаге?" ,
            answers: [ "Вариант 1" , "Вариант 2" , "Вариант 3" ]
        },
        {question: "А какие цели вообще приследуете??" ,
            answers: [ "Денег хочу" , "Знаний хочу" , "Власти , нефти , газа и алмазов хочу" ]
        },
        {question: "Чтож до сих пор не достигли?" ,
            answers: [ "Лень" , "Не знаю вообще с чего начинать" , "Нужен план" ]
        },
    ]
    }
];
