// https://api.notibot.ru/subscribers/{{shop_id}}

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    subscribers: [] ,
};


const slice = createSlice({
    name: 'subscribers',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setSubscribersSuccess(state, action) {
            // state.isLoading = false;
            state.subscribers =  action.payload ;
        },

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function getSubscribers(shopid) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/subscribers/${shopid}`);
            console.log("response" , response);
            const resp = response.data.data;
            // console.log("RESP" , resp);
            dispatch(slice.actions.setSubscribersSuccess(resp));

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function setCurrentLead(lead){
    return (dispatch) => {
        dispatch(slice.actions.setLead(lead));
    }
}


