import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    current_card: null ,
    shop_id: null ,
    article: null ,

};


const slice = createSlice({
    name: 'usercard',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setUsercardSuccess(state, action) {
            state.isLoading = false;
            state.current_card =  action.payload ;
        },

        setArticleSuccess(state, action) {
            state.isLoading = false;
            state.article =  action.payload ;
        },

        setShopID(state, action) {
            // state.isLoading = false;
            state.shop_id =  action.payload ;
        },

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}


export function getUserCardArticle(shopid){
    // https://api.notibot.ru/card/{{shop_id}}/article
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/card/${shopid}/article`);
            // console.log("response Article" , response);
            const resp = response.data.data;
            // console.log("group_id" , group_id);
            dispatch(slice.actions.setArticleSuccess(resp));

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}


export function getUsercard(shopid , parsing , tg_id=null ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/card/${shopid}?parsing=${parsing}&tg_id=${tg_id}`);
           // console.log("response" , response);
            const resp = response.data.data;
            // console.log("group_id" , group_id);
            dispatch(slice.actions.setUsercardSuccess(resp));

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}
export function setCurShop(shop){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setShopID(shop));
}



