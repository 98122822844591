import React, {Component} from "react";
import ErrorIndicator from "../../components/error-indicator/error-indicator";

class ErrorBoundry extends Component {

    // eslint-disable-next-line react/state-in-constructor
    state = {
        hasError : false ,
        error : null
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error);

        // для тестирования
        // const url = "https://api.notifio.ru/mylogs";
        const url = "https://functions.yandexcloud.net/d4euujo1atjpu240nrqt";
        console.log(error);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: error,
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            // eslint-disable-next-line no-shadow
            .catch((error) => {
                console.log("There was an error.", error);
            });

        this.setState({
            hasError: true ,
            error});
    }

    render(){
// console.log("Erorr" , this.state.error );
        // eslint-disable-next-line react/destructuring-assignment
        if(this.state.hasError){
            // eslint-disable-next-line react/destructuring-assignment
            return <ErrorIndicator info={`${this.state.error}`}/>
        }
        // eslint-disable-next-line react/destructuring-assignment,react/prop-types
        return this.props.children;
    }
}

export default ErrorBoundry;
