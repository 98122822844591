import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { AuthGuard } from 'src/auth/guard'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen } from 'src/components/loading-screen'
import NotifyArticleListView from "../../sections/dashboard/notify/auto/notify-article-list-view"

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/one'))
const NotifyListPage = lazy(() => import('src/pages/dashboard/notify/list'))
const NotifyCreatePage = lazy(() => import('src/pages/dashboard/notify/create'))
const NotifyEditPage = lazy(() => import('src/pages/dashboard/notify/edit'))

const AutoNotifyListPage = lazy(() => import('src/pages/dashboard/notify/auto/list'))
const AutoNotifyListOnTypePage = lazy(() => import('src/pages/dashboard/notify/auto/list_on_type'))
const AutoNotifyElementListPage = lazy(() => import('src/pages/dashboard/notify/auto/element_list'))

const ArticlePostsListPage = lazy(() => import('src/pages/dashboard/articles/lists'))
const ArticlePostDetailPage = lazy(() => import('src/pages/dashboard/articles/detail'))
const ArticleNewPostPage = lazy(() => import('src/pages/dashboard/articles/create'))


const AnalyticsTypesListPage = lazy(() => import('src/pages/dashboard/analytics/analytics_types_lists'))
const AnalyticsTypeDetailPage = lazy(() => import('src/pages/dashboard/analytics/analytics_type_detail_view'))

const GamesAnalyticsPage = lazy(() => import('src/pages/dashboard/games/analytics'))

const SubscribersListPage = lazy(() => import('src/pages/dashboard/subscribers/list'))
const ReferalsListPage = lazy(() => import('src/pages/dashboard/referals/list'))

const SettingsViewPage = lazy(() => import('src/pages/dashboard/settings/view'))
const SettingProductDisplay = lazy(() => import('src/pages/dashboard/settings/view-product'))


const FormsListPage = lazy(() => import('src/pages/dashboard/forms/list'))
const FormsDetailPage = lazy(() => import('src/pages/dashboard/forms/details'))

const UsercardPage = lazy(() => import('src/pages/dashboard/usercard/usercard-view'))

const PaymentPage = lazy(() => import('src/pages/dashboard/payment/payment-tariff-page'))


const PageTwo = lazy(() => import('src/pages/dashboard/two'))
const PageThree = lazy(() => import('src/pages/dashboard/three'))
const PageFour = lazy(() => import('src/pages/dashboard/four'))
const PageFive = lazy(() => import('src/pages/dashboard/five'))
const PageSix = lazy(() => import('src/pages/dashboard/six'))


// ---- general dashboard ----
const GeneralShopPage = lazy(() => import('src/pages/dashboard/general/general-views/general-shop-page'))
const GeneralUsercardPage = lazy(() => import('src/pages/dashboard/general/general-views/general-usercard-page'))
const GeneralArticlesPage = lazy(() => import('src/pages/dashboard/general/general-views/general-articles-page'))
const GeneralChatbotPage = lazy(() => import('src/pages/dashboard/general/general-views/general-chatbot-page'))
const GeneralToolsPage = lazy(() => import('src/pages/dashboard/general/general-views/general-tools-page'))
const GeneralSettingsPage = lazy(() => import('src/pages/dashboard/general/general-views/general-settings-page'))
const GeneralPaymentMainPage = lazy(() => import('src/pages/dashboard/general/payment/payment-main-page'))
const GeneralGoodsListPage = lazy(() => import('src/pages/dashboard/general/general-views/goods/goods-list-view'))
const GeneralCreateGoodsPage = lazy(() => import('src/pages/dashboard/general/general-views/goods/create'))
const GeneralCreateArticlePage = lazy(() => import('src/pages/dashboard/general/general-views/articles/create'))
const GeneralEditGoodsPage = lazy(() => import('src/pages/dashboard/general/general-views/goods/edit'))

const GeneralEditArticlePage = lazy(() => import('src/pages/dashboard/general/general-views/articles/edit'))

const GeneralEditGoodsBuyMessagePage = lazy(() => import('src/pages/dashboard/general/general-views/goods/dop_item/goods-buy-message'))

const GeneralGroupLockPage = lazy(() => import('src/pages/dashboard/general/general-views/goods/dop_item/goods-group-lock-page'))
// ---------- general pages -----

const GeneralPagesListPage = lazy(() => import('src/pages/dashboard/general/general-views/articles/pages-list-view'))
const GeneralPaymentAutoPage = lazy(() => import('src/pages/dashboard/general/payment/payment-auto-page'))
const GeneralPaymentManualPage = lazy(() => import('src/pages/dashboard/general/payment/payment-manual-page'))

const GeneralPaymentTypeDetailPage = lazy(() => import('src/pages/dashboard/general/payment/payment_type_detail_view'))


const GeneralEditSettingsAgreementPage = lazy(() => import('src/pages/dashboard/general/settings/oferta-page'))
const GeneralEditSettingsMetrikaPage = lazy(() => import('src/pages/dashboard/general/settings/metrika-page'))


// ----- INTEGRATIONS GENERAL ------ //
const GeneralIntegrationsMainPage = lazy(() => import('src/pages/dashboard/general/integrations/integrations-main-page'))
const GeneralIntegrationsGk = lazy(() => import('src/pages/dashboard/general/integrations/integrations-gk-page'))



// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>

        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'shop/:sid', element: <IndexPage /> },
      { path: 'ntflist', element: <NotifyListPage /> },
      { path: 'two', element: <PageTwo /> },
      { path: 'three', element: <PageThree /> },
      {
        path: 'subscribers',
        children: [
          { element: <SubscribersListPage />, index: true },
          // { path: ':id', element: <AutomateNotifyDetailPage /> },
          // { path: ':id/edit', element: <NotifyEditPage /> },
          // { path: 'new', element: <NotifyCreatePage /> },
          { path: 'list/:sid', element: <SubscribersListPage /> },
        ],
      },
      {
        path: 'referals',
        children: [
          { element: <ReferalsListPage />, index: true },
          { path: 'list/:sid', element: <ReferalsListPage /> },
        ],
      },
      {
        path: 'forms',
        children: [
          { element: <FormsListPage />, index: true },
          { path: 'list/:sid', element: <FormsListPage /> },
          { path: 'detail/:sid/:fid', element: <FormsDetailPage /> },
        ],
      },
      {
        path: 'settings',
        children: [
          // { element: <ReferalsListPage />, index: true },
          { path: ':sid', element: <SettingsViewPage /> },
          { path: 'shop/:sid', element: <SettingProductDisplay /> },

        ],
      },
      {
        path: 'integrations',
        children: [
          // { element: <ReferalsListPage />, index: true },
          { path: ':sid', element: <GeneralIntegrationsMainPage /> },
         //  { path: 'shop/:sid', element: <SettingProductDisplay /> },
         { path: ':sid/gk', element: <GeneralIntegrationsGk /> },


        ],
      },
      {
        path: 'general',
        children: [
          { path: ':sid/shop', element: <GeneralShopPage /> },
          { path: 'usercard/:sid', element: <GeneralUsercardPage /> },
          { path: 'articles/:sid', element: <GeneralArticlesPage /> },
          { path: 'chatbot/:sid', element: <GeneralChatbotPage /> },
          { path: 'settings/:sid', element: <GeneralSettingsPage /> },
          { path: 'tools/:sid', element: <GeneralToolsPage /> },
          { path: ':sid/payment', element: <GeneralPaymentMainPage /> },
          { path: ':sid/payment', element: <GeneralPaymentMainPage /> },
          { path: ':sid/goods', element: <GeneralGoodsListPage /> },
          { path: 'articles/:sid/list', element: <GeneralPagesListPage /> },
          { path: ':sid/goods/add', element: <GeneralCreateGoodsPage /> },
          { path: ':sid/articles/add', element: <GeneralCreateArticlePage /> },
          { path: ':sid/goods/:id/edit', element: <GeneralEditGoodsPage /> },
          { path: ':sid/article/:id/edit', element: <GeneralEditArticlePage /> },
          { path: ':sid/goods/:id/bmsg', element: <GeneralEditGoodsBuyMessagePage /> },
          { path: ':sid/goods/:id/grouplock', element: <GeneralGroupLockPage /> },
          { path: 'paymentManual/:sid', element: <GeneralPaymentManualPage /> },
          { path: 'settings/agreement/:sid', element: <GeneralEditSettingsAgreementPage /> },
          { path: 'settings/metrika/:sid', element: <GeneralEditSettingsMetrikaPage/> },
          {
            path: 'paymentAuto',
            children: [
              { path: ':sid/list', element: <GeneralPaymentAutoPage /> },
              { path: ':sid/:type', element: <GeneralPaymentTypeDetailPage /> },
            ]
          },
        ]
      },

      {
        path: 'pages',
        children: [
          { path: ':sid/list', element: <ArticlePostsListPage /> },
          { path: ':sid/detail/:id', element: <ArticlePostDetailPage /> },
          { path: ':sid/create', element: <ArticleNewPostPage /> },

        ]
      },

      {
        path: 'analytics',
        children: [
          { path: ':sid/list', element: <AnalyticsTypesListPage /> },
          { path: ':sid/:type', element: <AnalyticsTypeDetailPage /> },
        ]
      },

      {
        path: 'games',
        children: [
          { element: <GamesAnalyticsPage />, index: true },
          { path: ':sid/analytics', element: <GamesAnalyticsPage /> },
        ]
      },

      {
        path: 'notify',
        children: [
          { element: <NotifyListPage />, index: true },
          { path: 'list', element: <NotifyListPage /> },
          { path: 'auto', element: <AutoNotifyListPage /> },
          { path: 'auto/:type', element: <AutoNotifyListOnTypePage /> },
          { path: 'auto/:sid/:type/:elementid', element: <AutoNotifyListOnTypePage /> },
          // { path: ':id', element: <AutomateNotifyDetailPage /> },
          { path: ':id/edit', element: <NotifyEditPage /> },
          { path: ':sid/new', element: <NotifyCreatePage /> },
          { path: ':type/create', element: <NotifyCreatePage /> },
          { path: ':sid/:type/:elementid/create', element: <NotifyCreatePage /> },
          { path: 'shop/:sid', element: <NotifyListPage /> },
          { path: 'auto/element/:shopid/:type', element: <AutoNotifyElementListPage /> },
        ],
      },
      {
        path: 'usercard',
        children: [
          { element: <UsercardPage />, index: true },
          { path: ':sid', element: <UsercardPage /> },
          { path: ':sid/addblock/:type/:id', element: <UsercardPage /> },
        ],
      },
      {
        path: 'pays',
        children: [
          { element: <PaymentPage />, index: true },
          { path: ':sid', element: <PaymentPage /> },
        ],
      },
      {
        path: 'group',
        children: [
          { element: <PageFour />, index: true },
          { path: 'five', element: <PageFive /> },
          { path: 'six', element: <PageSix /> },
        ],
      },
    ],
  },
]
