import { createSlice } from '@reduxjs/toolkit';
// utils
import {isArray} from "lodash";
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    current_form: [] ,
    forms: [] ,
    user_answers: [] ,
    answer_del: false ,
};


const slice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setFormsSuccess(state, action) {
            state.isLoading = false;
            state.forms =  action.payload ;
        },
        setCurrentForm(state, action) {
            state.isLoading = false;
            state.current_form =  action.payload ;
        },
        setUserAnswers(state, action) {
            state.isLoading = false;
            state.user_answers =  action.payload ;
        },
        successDeleteItem(state , action){
            state.answer_del = true;
        } ,

        resetDeleteItemStatus(state , action){
            state.answer_del = false;
        } ,

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}

export function getForms(shopid ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/forms/${shopid}`);
            console.log("response FORMS" , response);
            const resp = response.data.data;
            const {success} = response.data;

            if(success ){
                dispatch(slice.actions.setFormsSuccess(resp));
            }
            // console.log("group_id" , group_id);

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function getFormID(shopid , formID , tg_id=null ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/forms/${shopid}/${formID}?tg_id=${tg_id}`);
            // console.log("response" , response);
            const resp = response.data.data;
            const {success} = response.data;

            console.log("resp" , resp);

            if(success ){
                dispatch(slice.actions.setCurrentForm(resp));
            }else if(!success  && resp.error === 'user has already filled the form'){
                    dispatch(slice.actions.setCurrentForm({complete: true ,
                        additional_text: resp.additional_text ,
                        form_type: resp.form_type
                    }));
                }

            // console.log("group_id" , group_id);


        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

// ===== ответы на форму юзера ====

export function getAnswersFormId(shopid , formID , tg_id=null) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const url = tg_id === null ? `/forms/${shopid}/${formID}/fillings` : `/forms/${shopid}/${formID}/fillings?tg_id=${tg_id}` ;
        try {
            const response = await axios.get(url);
            // console.log("response 23" , response);
            const resp = response.data.data;
            // console.log("group_id" , group_id);
            dispatch(slice.actions.setUserAnswers(resp));

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function delAnswersById(shopid , formid , ids){
    // https://api.notibot.ru/{shop_id}/{form_id}/fillings/delete
    console.log("IDS" , ids);
    const arrIds = isArray(ids) ? ids : [] ;
    const user_ids = {user_ids: arrIds}
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        dispatch(slice.actions.resetDeleteItemStatus());
        try {
            const response = await axios.post(`/forms/${shopid}/${formid}/fillings/delete` , user_ids);
            // console.log("delete" , response);
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.successDeleteItem());

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function setCurShop(shop){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setShopID(shop));
}



