import {CardMedia} from "@mui/material";
import ReactPlayer from 'react-player/youtube'
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Iconify from "../../components/iconify";

import Markdown from "../../components/markdown";
import ResponsivePlayer from "../../components/player/responsive-player";
import {useSettingsContext} from "../../components/settings";
import {useSelector} from "../../redux/store";
import {useLocales} from "../../locales";
import Image from "../../components/image";








// const postBody = `##### Telegram приложение внутри твоего канала за 10 минут \n\n ... \n\nNotibot - это telegram бот, который поможет тебе запустить telegram приложение с каталогом твоих продуктов как платных так и бесплатных, внутри твоего канала , чата или чат-боте
// \n\n`;

const postBody = `Используйте функции магазина, визитки , конструктора форм, страниц и чат-бота для создания настоящего webapp приложения для своего бизнеса
`;

export default function AboutNotibot (){
    const {t} = useLocales();
    const {  curShopId   } = useSelector((state) => state.startform);
    const {settings , isLoading } = useSelector((state) => state.shops);

   // const tgID = settings?.owner_id === '1D8eC2ppx1XCXaDWuPZHVi' ? 5049032924 : 174960070 ;
    const tgID = settings?.owner_id  ? settings?.owner_id : 'tg174960070' ;
    return (
        <>
          <Stack spacing={0.5} sx={{p:1}}>

            <Box sx={{m:1}}>
                <Stack spacing={1}>
                    <Image  sx={{borderRadius: 2 , mt:6 }}  src='/assets/images/splash_notibot.jpg'/>
                    <Markdown
                        children={postBody}
                        sx={{
                            px: 2,
                            '& p, li, ol': {
                                typography: 'body2',
                            },
                            '& ol': {
                                p: 0,
                                display: { md: 'flex' },
                                listStyleType: 'none',
                                '& li': {
                                    '&:first-of-type': {
                                        minWidth: 240,
                                        mb: { xs: 0.5, md: 0 },
                                    },
                                },
                            },
                        }}
                    />
                    {/* <ResponsivePlayer url="https://youtu.be/dnYhBilQi58"/> */}
                    <Alert variant="outlined" severity="info">
                        {t('footer.click_btn_for_create_app')}
                    </Alert>
                    <Button variant="contained" color='warning' size="large" href={`https://t.me/NotibotruBot?start=ref${tgID}`} >{t('footer.create_app_free')}</Button>
                </Stack>
            </Box>
          </Stack>

        </>
    );
}
