import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import {useEffect} from "react";
import Iconify from "../../../components/iconify";
import { useLocales } from "../../../locales"

HeaderBackButton.propTypes = {
    onBack: PropTypes.func ,
    isGeneral: PropTypes.bool ,
}



export default function HeaderBackButton({onBack , isGeneral}){

    const { t } = useLocales()

    return(
        <>
            {!isGeneral &&
                <Button
                    onClick={onBack}
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                    sx={{ mb: 1 }}
                >
                    {t(`system.back`)}
                </Button>
            }

        </>
    );
}
