import {
    format,
    getTime,
    formatDistanceToNow,
    fromUnixTime,
    getUnixTime,
    formatDistance,
    intlFormatDistance
} from 'date-fns';
import {ru}  from 'date-fns/locale' ;

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function ruDateFormat(date, newFormat = 'PPp') {
   // console.log("DATE" , date);
    const fm = newFormat || 'dd MMM yyyy';
    return date ? format(date, fm , {locale: ru}) : '';
}

export function fGetTimeStamp(date) {
    return date ? getUnixTime(new Date(date)) : '';
}
export function dateFromSec (timestamp){
return fromUnixTime(timestamp);
}

export function strSpecificDate(timestamp){
    const dt = fromUnixTime(timestamp);
    const fdt = format(dt, 'PPp' , {locale: ru});
    return fdt;
}

export function fDS(interval , intl=true , options={locale: ru}) {
    // console.log("interval" , interval);
    const nowSec = Math.floor(Date.now() / 1000);
    // const nowSec = getUnixTime(nowDate);


    // const nDate = toDate(nowSec+interval);
    const fnDate = fromUnixTime(nowSec+interval);
    const baseDate = fromUnixTime(nowSec);
    // console.log("fDS" , `${nowSec} ${fnDate}`);
    // let res = formatDistanceToNow(nDate, {
    //   includeSeconds: true ,
    //   // addSuffix: false,
    //   locale: ru
    // });

    let res = formatDistance(fnDate , baseDate, {addSuffix: false, locale: ru});


    if(intl){
        res = intlFormatDistance(fnDate , baseDate, options);
    }
    return res;
}

