import PropTypes from 'prop-types'
// @mui
import Box from '@mui/material/Box'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { useSettingsContext } from 'src/components/settings'
//
import { grey } from '@mui/material/colors'
import Paper from "@mui/material/Paper"
import Main from './main'
import Header from './header'
import NavMini from './nav-mini'
import NavVertical from './nav-vertical'
import NavHorizontal from './nav-horizontal'
import DashboardBottomNavigation from "../../sections/dashboard/bottom-navigation"
import {useRouter} from "../../routes/hooks";

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext()

  const lgUp = useResponsive('up', 'lg')

  const nav = useBoolean()

    const router = useRouter();
    const lct = router.location();

    const reqEditor =  /\/dashboard\/pages\/([\w]+)\/detail\/([\w]+)/gm;
    const reqEditor2 = /\/dashboard\/pages\/([\w]+)\/create/gm;
    const arLink =  [...lct.pathname.matchAll(reqEditor)];
    const arLink2 =  [...lct.pathname.matchAll(reqEditor2)];
    const isGeneralPage = lct.pathname.includes('/dashboard/shop/');

    const hideBottomNavEditorPage = arLink?.length > 0 || arLink2?.length > 0;

  const isHorizontal = settings.themeLayout === 'horizontal'

  const isMini = settings.themeLayout === 'mini'

  const renderNavMini = <NavMini />

  const renderHorizontal = <NavHorizontal />

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />

  const bottomNavHeight = hideBottomNavEditorPage ? 0 : 68 ;
  // console.log("lgUp" , lgUp);
  //   console.log("horz?" , isHorizontal);
  if (isHorizontal) {
    return (
      <>
           {/* <Header onOpenNav={nav.onTrue} /> */}

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main bottomNavHeight={bottomNavHeight}>{children}</Main>
      </>
    )
  }

  if (isMini) {
    return (
      <>
       <Header onOpenNav={nav.onTrue} />

       <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main bottomNavHeight={bottomNavHeight}>{children}</Main>
           {!hideBottomNavEditorPage &&
               <Paper sx={{ height: bottomNavHeight, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                   <DashboardBottomNavigation />
               </Paper>
           }
        </Box>

      </>
    )
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />
       {/*  <Header /> */}

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main bottomNavHeight={bottomNavHeight}>{children}</Main>
        {!hideBottomNavEditorPage &&
        <Paper sx={{ height: bottomNavHeight, position: 'fixed', bottom: 0, left: 0, right: 0, bgcolor: grey[200],  boxShadow: '0 -2px 10px rgba(0,0,0,0.2)',  zIndex: 10, }} elevation={3}>
        <DashboardBottomNavigation />
        </Paper>
        }
      </Box>
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
}
