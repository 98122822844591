
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  leaderboard: [],
};

const slice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.leaderboard = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LEADERBOARD
    getLeaderboardSuccess(state, action) {
      state.isLoading = false;
      state.leaderboard = action.payload;
    },

   
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}


export function getLeaderboard(shopid, gameid, userid) {

    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        // const response = await axios.get(`/statistic/products/${shopid}`);
        const response = await axios.get(`/games/${shopid}/${gameid}/${userid}`);

        console.log("LEADERBOARD" , response);
        dispatch(slice.actions.getLeaderboardSuccess(response.data.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
