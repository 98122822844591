import PropTypes from 'prop-types';
// utils
import 'src/utils/highlight';
import ReactMarkdown from 'react-markdown';
// markdown plugins
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
// @mui
import Link from '@mui/material/Link';
// routes
import { RouterLink } from 'src/routes/components';
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {AlertTitle} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
//


import Image from '../image';
//
import StyledMarkdown from './styles';
import ResponsivePlayer from "../player/responsive-player";

import {ArticleFormMarkdownLink} from "../../sections/article/forms/article-form-markdown-link";






const BlockPaper = styled(Paper)(({ theme }) => ({
    borderColor: theme.palette.primary.light ,
    // backgroundColor: theme.palette.primary.light ,
    textAlign: 'center',
}));


// ----------------------------------------------------------------------

export default function Markdown({ sx, ...other }) {


  return (
    <StyledMarkdown sx={sx}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw, rehypeHighlight, [remarkGfm, { singleTilde: false }]]}
        components={components}
        {...other}
      />
    </StyledMarkdown>
  );
}

Markdown.propTypes = {
  sx: PropTypes.object,
};

// ----------------------------------------------------------------------

const components = {
  img: ({ ...props }) => <Image alt={props.alt}  sx={{ borderRadius: 2 }} {...props} />,
  aside: ({ ...props}) => {console.log("ASIDE" , props)},
  blockquote: ({ ...props}) => {
   // console.log("POPS" , props);
    const elements = props.node.children.filter(el => el.type === 'element');
    const elementA = elements.find(el => el.tagName === 'a');
    const elementStrong = elements.find(el => el.tagName === 'strong')?.children;
    const elText = props.node.children.find(el => el.type === 'text')?.value || '';
    const elStrong = elementStrong?.length > 0 ? elementStrong[0].value : '';
    const boolA = !elementA;
    return(
        <>
            {!boolA &&
                <BlockPaper variant="outlined" sx={{p:2 }} >
                    <Stack spacing={2} >
                        <Typography variant="h6">{elStrong}</Typography>
                        <Typography variant="body2">{elText}</Typography>

                        <Button color="primary" variant="contained" href={elementA.properties.href}>
                            {elementA.children[0].value}
                        </Button>
                    </Stack>

                </BlockPaper>
                // <Alert variant="outlined" severity="info"  sx={{mt:2 , mb:2}}
                //        action={
                //            <Button color="primary" variant="contained" href={elementA.properties.href}>
                //                {elementA.children[0].value}
                //            </Button>
                //        }
                //
                // >
                //
                // </Alert>

            }
            {boolA &&
                <Alert color="warning" sx={{mt:2 , mb:2}}>
                    {props.children}
                </Alert>
            }

        </>

        )
  } ,
  figure: ({ ...props}) => <Box sx={{m:1}}>  {props.children}</Box>,
  iframe: ({ ...props}) => <ResponsivePlayer url={decodeURIComponent(props.src.slice(19))} />,
  a: ({ ...props }) => {

    let propsText = props.children;

    if(Array.isArray(props.children) ){
      if(props.children.length > 0){
        if(props.children[0]?.$$typeof){
          propsText = propsText[0].props.children;
        }
      }
    }

    const isHttp = props.href.includes('http');

    // return isHttp ? (
    //   <Link target="_blank" rel="noopener" {...props} />
    // ) : (
    //   <Link component={RouterLink} href={props.href} {...props}>
    //     {props.children}
    //   </Link>
    // );
    return (
        <ArticleFormMarkdownLink text={propsText} link={props.href}  />
    );
  },
};
