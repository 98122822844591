import PropTypes from 'prop-types';
import React, { useState } from 'react';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Button, Typography, CardActionArea } from '@mui/material';
// components
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import FileThumbnail from '../../../../components/file-thumbnail';
//

import {useLocales} from "../../../../locales";
import NotifyIconThumbnail from "../item/notify-icon-thumb";
import NotifyFilterButton from "./notify-filter-button";
// ----------------------------------------------------------------------

NotifyFilterCategory.propTypes = {
    onReset: PropTypes.func,
    filterCategory: PropTypes.array,
    onFilterCategory: PropTypes.func,
    optionsType: PropTypes.array,
};

export default function NotifyFilterCategory({ optionsType, filterCategory, onFilterCategory, onReset }) {

    const {t} = useLocales();

    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const isSelected = !!filterCategory.length;

    let filterCategoryLocale = [];

    if(filterCategory.length){
        filterCategoryLocale = filterCategory.map((i) => t(`notify.${i}`));
    }
    // const renderLabel = filterCategory.length ? filterCategory.slice(0, 2).join(',') : `${t('notify.all_type')}`;
    const renderLabel = filterCategory.length ? filterCategory.slice(0, 2).join(',') : `${t('notify.all_category')}`;
    return (
        <>
            <NotifyFilterButton
                isSelected={isSelected}
                endIcon={<Iconify icon={openPopover ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
                onClick={handleOpenPopover}
            >
                {renderLabel}
                {filterCategory.length > 2 && (
                    <Label color="info" sx={{ ml: 1 }}>
                        +{filterCategory.length - 2}
                    </Label>
                )}
            </NotifyFilterButton>

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ p: 2 }}>
                <Stack spacing={2.5}>
                    <Box
                        display="grid"
                        gridTemplateColumns='repeat(3, 2fr)'
                        gap={1}
                    >
                        {optionsType.map((type) => {
                            const selected = filterCategory.includes(type);

                            return (
                                <CardActionArea
                                    key={type}
                                    onClick={() => onFilterCategory(type)}
                                    sx={{
                                        p: 1,
                                        borderRadius: 1,
                                        cursor: 'pointer',
                                        color: 'text.secondary',
                                        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
                                        ...(selected && {
                                            color: 'text.primary',
                                            bgcolor: 'action.selected',
                                        }),
                                    }}
                                >
                                    <Stack spacing={0.5} direction="row" alignItems="center">
                                        <Iconify icon="streamline-emojis:open-file-folder" />
                                        <Typography variant="body2">{type}</Typography>
                                    </Stack>
                                </CardActionArea>
                            );
                        })}
                    </Box>

                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-end">
                        <Button variant="outlined" color="inherit" onClick={onReset}>
                            {`${t('system.clear')}`}
                        </Button>

                         <Button variant="contained" onClick={handleClosePopover}>
                            {`${t('system.close')}`}
                         </Button>
                    </Stack>
                </Stack>
            </MenuPopover>
        </>
    );
}
