import PropTypes from 'prop-types';
// @mui
import { TextField, InputAdornment } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

NotifyFilterName.propTypes = {
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
    placeholder: PropTypes.string ,
    sx: PropTypes.object ,
};

export default function NotifyFilterName({ filterName, onFilterName , placeholder , sx }) {
    return (
        <TextField
            size="small"
            value={filterName}
            onChange={onFilterName}
            placeholder={placeholder}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                ),
            }}
            fullWidth
            sx={sx}
        />
    );
}
