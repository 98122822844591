import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    filepath: null ,
    result_url: null ,
    url : null
};

const slice = createSlice({
    name: 'uploadys',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // START LOADING
        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // UPLOAD FILE
        uploadSuccess(state, action) {
            state.isLoading = false;
            state.filepath = action.payload;
        },

        setPathFile(state , action){
            state.filepath = action.payload;
        },

        setPathFiles(state , action){
            if(state.filepath === null)state.filepath = [];
            state.filepath = [...state.filepath , action.payload];
        },

        setResultUrl(state , action){
            state.result_url = action.payload;
        } ,

        setUrl(state , action){
            state.url = action.payload;
        }
        ,
        // ==== array =====
        setArrResultUrl(state , action){
            if(state.result_url === null)state.result_url = [];
            state.result_url =[...state.result_url , action.payload];
        } ,

        setArrUrl(state , action){
            if(state.url === null)state.url = [];
            state.url =[...state.url , action.payload];
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function uploadFile() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/products');
            dispatch(slice.actions.getUploadUrl(response.data.products));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
// ----------------------
export function clearUploadValue(){
    return async (dispatch) => {
        dispatch(slice.actions.setPathFile(null));
        dispatch(slice.actions.setResultUrl(null));
    };
}
// ----------------------------------------------------------------------

const uploadArrayToYa = async(data , fimages) => {

    let arRes = {error: false , result: false};
    let resImg = null;
    const formFields = data.fields;
    const resultUrl = data.result_url;
    const url = data.url;
    const bodyForm = new FormData();
    const keys = Object.keys(formFields);
    keys.forEach((key, index) => {
        if(key === 'key') resImg = formFields[key]; // slice.actions.setPathFile(formFields[key]);
        // console.log(`REDUCER ${key} : ${formFields[key]}`);
        bodyForm.append(key , formFields[key]);
    });
    bodyForm.append('file' , fimages);
    const resp = await axios.post(data.url, bodyForm, {headers: {"Content-Type": "multipart/form-data"}})
        .then((axiosresp) => {
            console.log(`axios ${fimages} >>>>` , axiosresp);
            // console.log("REDUCER axiosresp" , axiosresp.status);
            if(axiosresp.status === 204){
                console.log("REDUCER IMG LOAD" , resImg );
                // dispatch(slice.actions.uploadSuccess(resImg));
                // dispatch(slice.actions.setResultUrl(resultUrl));
                // dispatch(slice.actions.setUrl(url)); // домен стораджа
                const resact = {
                    uploadSuccess:resImg ,
                    setResultUrl: resultUrl ,
                    setUrl: url
                }
                arRes = {...arRes , result: resact}
            }else{
                arRes = {...arRes , error: axiosresp.status }
            }

        })
        .catch((error) => {
            console.error(error);
            arRes = {...arRes , error }
            // dispatch(slice.actions.hasError(error));
        });

        return arRes;
}

export function getUploadUrl(fimages) {

    const str_count = fimages?.length > 1 ? `?count=${fimages.length}`  : '';

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            // let resImg = null;
            const response = await axios.get(`/get_upload_url${str_count}`);
            // console.log("UPLOAD RESPONSE YA :::" , Array.isArray(response.data));
            console.log("response" , response);
            const mult = Array.isArray(response.data);

            // let arResult = {};
            if(mult){
                // response.data.map(item => {})
                // uploadArrayToYa(response.data);

                response.data.forEach((item, index) => {
                    console.log("forEach item" , item);
                    console.log("fimages[index]" , fimages[index]);
                    uploadArrayToYa(item , fimages[index]).then(itemRes => {
                        console.log("itemRes" , itemRes);
                        if(itemRes.error){
                            dispatch(slice.actions.hasError(itemRes.error));
                        }else if(!itemRes.error){
                            console.log("::::::: >>>>" , itemRes);
                            dispatch(slice.actions.setPathFiles(itemRes.result.uploadSuccess));
                            dispatch(slice.actions.setArrResultUrl(itemRes.result.setResultUrl));
                            dispatch(slice.actions.setArrUrl(itemRes.result.setUrl)); // домен стораджа
                        }
                    })
                })
                dispatch(slice.actions.stopLoading());

            }else{
                uploadArrayToYa(response.data , fimages[0]).then(res => {
                    if(res.error){
                        dispatch(slice.actions.hasError(res.error));
                    }else if(!res.error){
                        dispatch(slice.actions.uploadSuccess(res.result.uploadSuccess));
                        dispatch(slice.actions.setResultUrl(res.result.setResultUrl));
                        dispatch(slice.actions.setUrl(res.result.setUrl)); // домен стораджа
                    }
                });

            }

            // const formFields = response.data.fields;
            // const resultUrl = response.data.result_url;
            // const url = response.data.url;
            // const bodyForm = new FormData();
            // const keys = Object.keys(formFields);
            // keys.forEach((key, index) => {
            //     if(key === 'key') resImg = formFields[key]; // slice.actions.setPathFile(formFields[key]);
            //     // console.log(`REDUCER ${key} : ${formFields[key]}`);
            //     bodyForm.append(key , formFields[key]);
            // });
            //
            // console.log("FIMages" , fimages);
            //
            // bodyForm.append('file' , fimages);
            // const resp = await axios.post(response.data.url, bodyForm, {headers: {"Content-Type": "multipart/form-data"}})
            //     .then((axiosresp) => {
            //         console.log("===>>>" , axiosresp);
            //         // console.log("REDUCER axiosresp" , axiosresp.status);
            //         if(axiosresp.status === 204){
            //             console.log("REDUCER IMG LOAD" , resImg );
            //             dispatch(slice.actions.uploadSuccess(resImg));
            //             dispatch(slice.actions.setResultUrl(resultUrl));
            //             dispatch(slice.actions.setUrl(url)); // домен стораджа
            //         }
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //         dispatch(slice.actions.hasError(error));
            //     });

            // dispatch(slice.actions.getUploadUrl(response.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}


// const getDataUploadForm = async (values , loading) => {
//     console.log(`3 - ${loading.current}`);
//
//     try {
//         // await new Promise((resolve) => setTimeout(resolve, 500));
//         loading.current = true;
//         let resImg = null;
//         const response = await axios.get('/get_upload_url');
//         console.log("IMAGES" , values);
//
//         if(values){
//             const formFields = response.data.fields;
//             const bodyForm = new FormData();
//             const keys = Object.keys(formFields);
//             keys.forEach((key, index) => {
//                 if(key === 'key')resImg = formFields[key];
//                 console.log(`${key} : ${formFields[key]}`);
//                 bodyForm.append(key , formFields[key]);
//             });
//
//             bodyForm.append('file' , values);
//             const resp = await axios.post(response.data.url, bodyForm, {headers: {"Content-Type": "multipart/form-data"}})
//                 .then((axiosresp) => {
//                     console.log("axiosresp" , axiosresp.status);
//                     if(axiosresp.status === 204){
//                         console.log("IMG LOAD" );
//
//                         loading.current = false;
//                         console.log(`4 - ${loading.current}`);
//                     }
//                 })
//                 .catch((error) => {
//                     console.error(error);
//                 });
//         }
//     } catch (error) {
//         console.error(error);
//     }
//
// }
