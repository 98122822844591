import {paths} from "../routes/paths";

export function selfLink(link , shopid){

    const reqxLink =  /post#([\w\d]+)/g;
    const reqxLinkGood =  /good#([\w\d]+)/g;

    const arLink =  [...link.matchAll(reqxLink)];
    const arLinkGood =  [...link.matchAll(reqxLinkGood)];


    let postID = null;
    let goodID = null;


    if(arLink.length > 0 && arLink[0][1] !== undefined){
        // console.log("regx" , arLink[0][1]);
        postID = arLink[0][1];
    }

    if(arLinkGood.length > 0 && arLinkGood[0][1] !== undefined){
        // console.log("regx good" , arLinkGood[0][1]);
        goodID = arLinkGood[0][1];
    }

   let obj = {type: "location" , link}

        if(postID){
            // console.log("LINK" , link);
            obj = {type: 'router' , link: paths.product.article(shopid , postID)};
        }else if(goodID){
            obj = {type: 'router' , link: paths.product.details(shopid , goodID)};
        }
        return obj;
}

export function isTgLink(link){
    const reqxTgLink =  /t\.me\/(.*)/g;
    const arLinkTg =  [...link.matchAll(reqxTgLink)];
    // console.log("TG LINK" , arLinkTg[0]?.index);
    if(Array.isArray(arLinkTg)){
      if(arLinkTg[0]){
          if(arLinkTg[0].length >= 2){
              return true
          }
      }
    }
    return false
}

export function getLinkElementID(arrLink){
    if(arrLink.length > 0 ){
        if(arrLink[0].length > 0 && arrLink[0][1] !== undefined){
            return arrLink[0][1];
        }
    }
    return null;
}
