// routes
import { paths } from 'src/routes/paths';
import {NOTIFY_TYPES , NOTIFY_TEXT_VARS} from './sections/dashboard/notify/type';
import {arCodes} from './sections/article/codes';
// API
// ----------------------------------------------------------------------
export const NOTIFY_TYPE = NOTIFY_TYPES;
export const NOTIFY_TXT_VARS = NOTIFY_TEXT_VARS;
export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const ARTICLE_CODES = arCodes ;
export const ARRAY_ACCESS_TARIFF = ['advanced' , 'demo'];
export const TG_NULL_OBJECT = { id: 174960070, first_name: "Тест" , last_name: "Женька" , username: "@" , photo_url: null }
export const HSLIDER_MAXLINE_WIDTH = [ {len: 2 , mw: 200 , maxw: '48.5%' } , {len: 1 , mw: '90%' , maxw: '100%' } , {len: 3 , mw: '40%' , maxw: '48.5%' } , {len: 4 , mw: '20%' , maxw: '25%' } , ]



export const LINK_INSTRUCTION = {
  shop_page: 'https://t.me/iv?url=https://telegra.ph/Knopka-na-Magazin-v-kanalchat-07-09&rhash=e3a3030b52f043' ,
  usercard_page: 'https://t.me/iv?url=https://telegra.ph/Knopka-na-Vizitku-v-kanalchat-07-09&rhash=e3a3030b52f043' ,
  articles_page : 'https://t.me/iv?url=https://telegra.ph/Kak-sozdat-velikolepnuyu-stranicu-07-09&rhash=e3a3030b52f043' ,
  forms_page : 'https://t.me/iv?url=https://telegra.ph/Kak-sozdat-formu-07-09&rhash=e3a3030b52f043' ,
  how_to_usercard: 'https://t.me/iv?url=https://telegra.ph/Kak-nastroit-vizitku-07-10&rhash=e3a3030b52f043' ,
  yookassa: 'https://t.me/iv?url=https://telegra.ph/Podklyuchenie-YUkassa-07-16&rhash=e3a3030b52f043' ,
}

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
