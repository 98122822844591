import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {YandexMetrika} from "yandex-metrika-react";
import { HeaderSimple as Header } from '../_common';
import MiniFooter from "./footer";
import LocalizationProvider from "../../locales/localization-provider";
import {useSelector} from "../../redux/store";


// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
    const { settings , isLoading } = useSelector((state) => state.shops);
    const yaMetrika = !!Number( settings?.metric );

  return (
    <>
    {yaMetrika &&
        <YandexMetrika
            counterId={settings?.metric}
            options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                triggerEvent: true,
            }}
        />

    }

    {children}

       {/* <Header /> */}

      <MiniFooter />

    </>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
};
