import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";
import {fGetTimeStamp} from "../../utils/format-time";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    groups_lock: {count:0 , gid: null } ,
    groups_member: [] ,
    member_left: {} ,
    focus_click: {} ,
    shop_id: null ,
    first_open: {} ,
};


const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setMemberSuccess(state, action) {
           // state.isLoading = false;
            if(!state.groups_member.includes(action.payload)){
                state.groups_member =  [...state.groups_member , action.payload ];
            }

        },
        setMemberLeft(state, action) {
            // state.isLoading = false;
            state.member_left =  action.payload ;
        },
        setFocusClick(state, action) {
            // state.isLoading = false;
            state.focus_click =  action.payload ;
        },
        setShopID(state, action) {
            // state.isLoading = false;
            state.shop_id =  action.payload ;
        },
        setLockSuccess(state, action) {
            // state.isLoading = false;
            state.groups_lock.gid =  action.payload ;
            // eslint-disable-next-line no-plusplus
            state.groups_lock.count++;
        },

        setFirstOpenSuccess(state, action){
            const {pid , lastsec , unix} = action.payload;
            state.isLoading = false;
            state.first_open = {...state.first_open , [pid]: {lastsec , unix} }
        } ,
        clearLock(state, action){
            state.groups_lock = {count: 0 , gid: action.payload }
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}

export function setMemberSuccess(group_id){
    return (dispatch) => {
        dispatch(slice.actions.setMemberSuccess(group_id));
    }
}

export function setLockGroup(val){
    return (dispatch) => {
        dispatch(slice.actions.setLockSuccess(val));
    }
}

export function clearLock(val){
    return (dispatch) => {
        dispatch(slice.actions.clearLock(val));
    }
}

export function setMemberLeft(val){
    console.log("setMemberLeft" , val);
    return (dispatch) => {
        dispatch(slice.actions.setMemberLeft(val));
    }
}

export function setFocusClick(val){
    return (dispatch) => {
        dispatch(slice.actions.setFocusClick(val));
    }
}

export function checkMemberGroup(shopid , user_id , group_id) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/grouplock/${shopid}/${group_id[0]}/${user_id}`);
            // console.log("response" , response.data.data);
            const resp = response.data.data;
            // console.log("group_id" , group_id);
            if(resp.status === 'member' || resp.status === 'creator'){

                dispatch(slice.actions.setMemberSuccess(group_id[0]));
            }
            if(resp.status === 'left'){
                dispatch(slice.actions.setLockSuccess(resp));
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}
// https://api.notibot.ru/open/{{shop_id}}/product/{{product_id}}/{{tg_id}}?order={{order}}
export function firstOpenProduct(shopid , user_id , prod_id){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/open/${shopid}/product/${prod_id}/${user_id}?order=first`);
            console.log("response" , response.data);
            const resp = response.data.data;
            const nowInSec = fGetTimeStamp(new Date());
            const timestampFirstOpen = nowInSec - resp;
            const objRes = {pid: prod_id , lastsec: resp , unix: timestampFirstOpen };
            dispatch(slice.actions.setFirstOpenSuccess(objRes));
        } catch (e) {
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function setCurShop(shop){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setShopID(shop));
}

export function getQuizById(id){

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const res = _mockQuiz.find(item => item.id === id)
            dispatch(slice.actions.setCurrentQuiz(res));
        }catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function setCurrentLead(lead){
    return (dispatch) => {
        dispatch(slice.actions.setLead(lead));
    }
}


