


const iconUrl = (icon) => `/assets/icons/files/${icon}.png`;


function strWhenBooking(event){
    let str;
    switch (event){
        case 'today':
            str = "on_the_day_of_the_visit";
            break;
        case 'tomorrow':
            str = "one_day_before_the_visit";
            break;
        default:
            str = event;
    }

    return str;
}

export function transTypeEvent(type , event){
    let str;
    switch(type){
        case 'remind_about_event' :
            str = strWhenBooking(event);
            break;
        case 'reinvite':
            break;
        default:

    }

    return str;
}


export function notifyTypeThumb(type) {
    let thumb;

    switch (type) {
        case 'on_event':
            thumb = iconUrl('calendar');
            break;
        case 'remind_about_event':

            thumb = iconUrl('on_event');
            break;
        case 'reinvite':
            thumb = iconUrl('reinvite');
            break;
        case 'specific_date':
            thumb = iconUrl('remind_about_event');
            break;

        default:
            thumb = iconUrl('reminders');
    }
    return thumb;
}




