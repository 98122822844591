
import { createSlice } from '@reduxjs/toolkit'
// utils
import axios from '../../utils/axios'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  referralGame: [],
}

const slice = createSlice({
  name: 'referralGame',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
      state.referralGame = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET REFERRAL
    getReferralSuccess(state, action) {
      state.isLoading = false
      state.referralGame = action.payload
    },


  },
})

// Reducer
export default slice.reducer

// Actions
export const { actions } = slice

// ----------------------------------------------------------------------

export function setLoading(val) {
  return (dispatch) => {
    dispatch(slice.actions.setLoading(val))
  }
}


// export function getReferral(shopid, gameid, userid) {
export function getReferral(userid) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      // const response = await axios.get(`/statistic/products/${shopid}`);
      // const response = await axios.get(`games/get-referral/${shopid}/${gameid}/${userid}`);
      const response = await axios.get(`games/get-referral/${userid}`)


      console.log("REFERRAL", response)
      dispatch(slice.actions.getReferralSuccess(response.data.data))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}
