export const arCodes = [
    {code: '&a001;' , set: {component: 'alert' , variant: null , severity: 'info'}} ,
    {code: '&a002;' , set: {component: 'alert' ,variant: null , severity: 'error'}} ,
    {code: '&a003;' , set: {component: 'alert' ,variant: null , severity: 'warning'}} ,
    {code: '&a004;' , set: {component: 'alert' ,variant: null , severity: 'success'}} ,
    {code: '&a011;' , set: {component: 'alert' ,variant: 'filled' , severity: 'info'}} ,
    {code: '&a012;' , set: {component: 'alert' ,variant: 'filled' , severity: 'error'}} ,
    {code: '&a013;' , set: {component: 'alert' ,variant: 'filled' , severity: 'warning'}} ,
    {code: '&a014;' , set: {component: 'alert' ,variant: 'filled' , severity: 'success'}} ,
    {code: '&a021;' , set: {component: 'alert' ,variant: 'outlined' , severity: 'info'}} ,
    {code: '&a022;' , set: {component: 'alert' ,variant: 'outlined' , severity: 'error'}} ,
    {code: '&a023;' , set: {component: 'alert' ,variant: 'outlined' , severity: 'warning'}} ,
    {code: '&a024;' , set: {component: 'alert' ,variant: 'outlined' , severity: 'success'}} ,
    {code: '&a025;' , set: {component: 'divider' ,variant: 'dashed' , severity: 'grey'}} ,
    {code: '&a026;' , set: {component: 'header' ,variant: 'overline' , severity: 'grey'}} ,
    // button
    {code: '&b11;' , set: {component: 'button' , variant: 'contained' , severity: 'info'}} ,
    {code: '&b12;' , set: {component: 'button' ,variant: 'contained' , severity: 'error'}} ,
    {code: '&b13;' , set: {component: 'button' ,variant: 'contained' , severity: 'warning'}} ,
    {code: '&b14;' , set: {component: 'button' ,variant: 'contained' , severity: 'success'}} ,
    {code: '&b15;' , set: {component: 'button' ,variant: 'contained' , severity: 'primary'}} ,
    {code: '&b16;' , set: {component: 'button' ,variant: 'contained' , severity: 'secondary'}} ,
    {code: '&b17;' , set: {component: 'button' ,variant: 'contained' , severity: 'black'}} ,

    {code: '&b21;' , set: {component: 'button' , variant: 'outlined' , severity: 'info'}} ,
    {code: '&b22;' , set: {component: 'button' ,variant: 'outlined' , severity: 'error'}} ,
    {code: '&b23;' , set: {component: 'button' ,variant: 'outlined' , severity: 'warning'}} ,
    {code: '&b24;' , set: {component: 'button' ,variant: 'outlined' , severity: 'success'}} ,
    {code: '&b25;' , set: {component: 'button' ,variant: 'outlined' , severity: 'primary'}} ,
    {code: '&b26;' , set: {component: 'button' ,variant: 'outlined' , severity: 'secondary'}} ,
    {code: '&b27;' , set: {component: 'button' ,variant: 'outlined' , severity: 'black'}} ,

    {code: '&b31;' , set: {component: 'button' , variant: 'text' , severity: 'info'}} ,
    {code: '&b32;' , set: {component: 'button' ,variant: 'text' , severity: 'error'}} ,
    {code: '&b33;' , set: {component: 'button' ,variant: 'text' , severity: 'warning'}} ,
    {code: '&b34;' , set: {component: 'button' ,variant: 'text' , severity: 'success'}} ,
    {code: '&b35;' , set: {component: 'button' ,variant: 'text' , severity: 'primary'}} ,
    {code: '&b36;' , set: {component: 'button' ,variant: 'text' , severity: 'secondary'}} ,
    {code: '&b37;' , set: {component: 'button' ,variant: 'text' , severity: 'black'}} ,

    {code: '&b9;' , set: {component: 'banner' , variant: 'default' , severity: 'info'}} ,
    {code: '&b8;' , set: {component: 'banner' ,variant: 'default' , severity: 'error'}} ,
    {code: '&b7;' , set: {component: 'banner' ,variant: 'default' , severity: 'warning'}} ,
    {code: '&b6;' , set: {component: 'banner' ,variant: 'default' , severity: 'success'}} ,
    {code: '&b5;' , set: {component: 'banner' ,variant: 'default' , severity: 'primary'}} ,
    {code: '&b4;' , set: {component: 'banner' ,variant: 'default' , severity: 'secondary'}} ,
    {code: '&b3;' , set: {component: 'banner' ,variant: 'default' , severity: 'black'}} ,

    {code: '&b49;' , set: {component: 'banner' , variant: 'light' , severity: 'info'}} ,
    {code: '&b48;' , set: {component: 'banner' ,variant: 'light' , severity: 'error'}} ,
    {code: '&b47;' , set: {component: 'banner' ,variant: 'light' , severity: 'warning'}} ,
    {code: '&b46;' , set: {component: 'banner' ,variant: 'light' , severity: 'success'}} ,
    {code: '&b45;' , set: {component: 'banner' ,variant: 'light' , severity: 'primary'}} ,
    {code: '&b44;' , set: {component: 'banner' ,variant: 'light' , severity: 'secondary'}} ,
    {code: '&b43;' , set: {component: 'banner' ,variant: 'light' , severity: 'black'}} ,

    {code: '&b59;' , set: {component: 'banner' , variant: 'grd90' , severity: 'info'}} ,
    {code: '&b58;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'error'}} ,
    {code: '&b57;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'warning'}} ,
    {code: '&b56;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'success'}} ,
    {code: '&b55;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'primary'}} ,
    {code: '&b54;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'secondary'}} ,
    {code: '&b53;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'black'}} ,
    {code: '&b52;' , set: {component: 'banner' ,variant: 'grd90' , severity: 'white'}} ,

    {code: '&b69;' , set: {component: 'banner' , variant: 'ghost' , severity: 'info'}} ,
    {code: '&b68;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'error'}} ,
    {code: '&b67;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'warning'}} ,
    {code: '&b66;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'success'}} ,
    {code: '&b65;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'primary'}} ,
    {code: '&b64;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'secondary'}} ,
    {code: '&b63;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'black'}} ,
    {code: '&b62;' , set: {component: 'banner' ,variant: 'ghost' , severity: 'white'}} ,

];
