import {forwardRef, useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {Slide} from "@mui/material";
import Stack from "@mui/material/Stack";
import Iconify from "../../components/iconify";
import AboutNotibot from "./about";
import LangPopover from "../_common/lang-popover";
import {useLocales} from "../../locales";
import FooterOferta from "./oferta";
import MadeByBottom from "./made-by-bottom";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function MiniFooter(){
    const [open , setOpen] = useState(false);
    const hideFoot = false;
    const {t} = useLocales();
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

  const promoDialog = (
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* <AppBar sx={{ position: 'relative' }}> */}
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Iconify icon="material-symbols:arrow-back" />

            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="subtitle2" component="div"  onClick={handleClose}>
                {t('system.back')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
                {t('close')}
            </Button>
          </Toolbar>
        </AppBar>
        <AboutNotibot />
      </Dialog>
  );

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        pb: 1,
        textAlign: 'center',
        position: 'relative',
        // bgcolor: 'background.default',
      }}
    >
      <Container>
        {/* <Logo sx={{ mb: 1, mx: 'auto' }} /> */}
        <Stack>
            <Box sx={{mx: 'auto' }}>
                <FooterOferta />
            </Box>

          <Box><LangPopover /></Box>

          <MadeByBottom onOpen={handleClickOpen} />
             {/* <Button variant="text" onClick={handleClickOpen} sx={{p:0.1}}> */}
             {/* <Typography variant="caption" component="div" sx={{lineHeight:0.7}} color="text.secondary"> */}
             {/*    {t('footer.do_you_want_this')} */}
             {/* </Typography> */}
             {/* </Button> */}
        </Stack>

      </Container>
    </Box>
  );

    return(
      <>
        {!hideFoot && simpleFooter}
        {promoDialog}
      </>
    );
}
