
export const NOTIFY_TYPES = [
    'on_event',
    'remind_about_event',
    'reinvite',
    'specific_date' ,
    'collect_feedback' ,
    'birthday'
];

export const NOTIFY_TEXT_VARS = [
    'name',
    // 'date_day_mon',
    // 'date_number' ,
    // 'day_of_week' ,
    // 'book_start_time' ,
    // 'book_end_time' ,
    // 'master' ,
    // 'price' ,
    // 'services' ,
    // 'review_link'


];

/*
* {name} - Для подстановки имени клиента
{day_month} Для даты и месяца (12 декабря)
{digital_date} Для даты и месяца (числом - 12.12)
{day_of_week} Для дня недели
{start_time} Время начала услуги
{end_time} Время окончания услуги
{master} Имя мастера
{price} Цена
{services} Услуга или список услуг
{review_link} Ссылка на Yclients для отзыва
{record_link} Ссылка на Yclients на удаление записи клиентом
* */
