import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
//
import { notifyTypeThumb } from '../utils';
import Iconify from "../../../../components/iconify";

// ----------------------------------------------------------------------

NotifyIconThumbnail.propTypes = {
    sx: PropTypes.object,
    imgSx: PropTypes.object,
    type: PropTypes.string
};

export default function NotifyIconThumbnail({ type,  sx, imgSx }) {
    console.log("TTT" , type);
    return (
        <>
            {type === 'on_event' &&
                <Iconify icon="streamline-emojis:rocket"
                         sx={{
                    width: 32,
                    height: 32,
                    flexShrink: 0,
                    ...sx,
                }}/>
            }
            {type === 'specific_date' &&
                <Box
                    component="img"
                    src={notifyTypeThumb(type)}
                    sx={{
                        width: 32,
                        height: 32,
                        flexShrink: 0,
                        ...sx,
                    }}
                />
            }


        </>
    );
}
