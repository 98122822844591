
import { createSlice } from '@reduxjs/toolkit'
// utils
import axios from '../../utils/axios'

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    settingsPayment: [],
    integrationData: [],
}

const slice = createSlice({
    name: 'settingsPayment',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.settingsPayment = []
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PAYMENT
        getSettingsPaymentSuccess(state, action) {
            state.isLoading = false
            state.settingsPayment = action.payload
        },

        // GET INTEGRATION DATA SUCCESS
        getIntegrationDataSuccess(state, action) {
            state.isLoading = false
            state.integrationData = action.payload
        },

        // RESET INTEGRATION DATA
        resetIntegrationData(state) {
            state.integrationData = null
        },


    },
})

// Reducer
export default slice.reducer

// Actions
export const { actions } = slice

// ----------------------------------------------------------------------

export function setLoading(val) {
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val))
    }
}


export function getSettingsPayment(shopid) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading())
        try {

            const response = await axios.get(`payment/${shopid}`)


            console.log("PAYMENT", response)
            dispatch(slice.actions.getSettingsPaymentSuccess(response.data.data))
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getIntegrationData(shopid) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get(`getcourse/${shopid}/info`)
            console.log("INTEGRATION DATA", response)
            dispatch(slice.actions.getIntegrationDataSuccess(response.data.data))
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
