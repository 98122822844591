
import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useSelector} from "../../../redux/store";
import {paths} from "../../../routes/paths";
import {useRouter} from "../../../routes/hooks";


import {isTgLink,getLinkElementID} from "../../../utils/article-self-link";
import {replaceButtonCode} from "../../../utils/article-codes";
import Iconify from "../../../components/iconify";

// ---------------TELEGRAMM ---------------
const tg = window.Telegram?.WebApp;
const userData = tg?.initDataUnsafe  ;
// --------------- END --------------------
const tgUser = userData?.user || null;

export function ArticleFormMarkdownLink({ text ,  link  }){

    const router = useRouter();
    const {  curShopId   } = useSelector((state) => state.startform);
    let showBtn = false;
    const origText = Array.isArray(text) ? text[0] : text ;
    let cfgBtn = {text: origText , variant: "contained" , color: "primary"}


    const reqxLink =  /post#([\w\d]+)/g;
    // const reqxLinkGood =  /good#([\w\d]+)/g;
    // const reqxLinkForm =  /form#([\w\d]+)/g;
    // const arLinkGood =  [...link.matchAll(reqxLinkGood)];
    const arLink =  [...link.matchAll(reqxLink)];
    // const arLinkForm =  [...link.matchAll(reqxLinkForm)];




    const postID = getLinkElementID(arLink);
    // const goodID = getElementID(getLinkElementID);
    // const formID = getElementID(getLinkElementID);


    // if(arLink.length > 0 && arLink[0][1] !== undefined){
    //     postID = arLink[0][1];
    // }
    //
    // if(arLinkGood.length > 0 && arLinkGood[0][1] !== undefined){
    //
    //     goodID = arLinkGood[0][1];
    // }

    const handleOnClick2 = () => {
        console.log("link" , isTgLink(link));

    }
    const handleOnClick = () => {


        if(postID){
            // console.log("LINK" , link);
            router.push(paths.product.article(curShopId , postID));
        }
        // else if(goodID){
        //     router.push(paths.product.details(curShopId , goodID));
        // }else if(formID){
        //     // console.log("FORM");
        //     setOpenFDialog(true);
        // }
        else if(isTgLink(link)){
            tg.openTelegramLink(link);
        }else{
            // eslint-disable-next-line no-lonely-if
            if(tgUser?.id)tg.openLink(link);
            else window.location.href = link;

            // window.location.href = link;
        }
    }

    if(!showBtn){
        const codeBtn = replaceButtonCode(origText);
        if(codeBtn){
            cfgBtn = codeBtn;
            showBtn = true;
        }
    }

    // const handleCloseFDialog = () => {
    //     setOpenFDialog(false);
    // }


    return(
        <>
            {showBtn &&
                <Stack spacing={2} sx={{my:2}}>
                    <Button color={cfgBtn.color}
                            variant={cfgBtn.variant}
                            onClick={handleOnClick}
                            startIcon={cfgBtn?.icon?.position === 'start' ? <Iconify icon={cfgBtn?.icon?.code}/> : null }
                            endIcon={cfgBtn?.icon?.position === 'end' ? <Iconify icon={cfgBtn?.icon?.code}/> : null }
                    >
                        {cfgBtn.text}
                    </Button>
                </Stack>
            }
            {!showBtn &&
                <Link onClick={handleOnClick} sx={{cursor: 'pointer'}}>
                    {cfgBtn.text}
                </Link>
            }
        </>

    );
}

ArticleFormMarkdownLink.propTypes = {
    text: PropTypes.any ,
    link: PropTypes.string ,
}
