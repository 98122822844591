import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    content: null ,
    current_lp: null ,
    current_quiz: null ,
    current_card: null ,
    lead: [] ,
};


const slice = createSlice({
    name: 'lp',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setCurrentLp(state, action) {
            state.isLoading = false;
            state.current_lp = action.payload;
        },

        setCurrentCard(state, action) {
            state.isLoading = false;
            state.current_card = action.payload;
        },

        setCurrentQuiz(state, action) {
            state.isLoading = false;
            state.current_quiz = action.payload;
        },

        setLead(state, action) {
            state.isLoading = false;
            state.lead = action.payload;
        },


    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function getCardByShopId(shopid , tg_id = null){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/card/${shopid}?parsing=1&tg_id=${tg_id}`);
            // console.log("card resp =>>> " , response );
            dispatch(slice.actions.setCurrentCard(response.data.data));
        }catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function getLpById(id){

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const res = _mockLp.find(item => item.id === id)
            // const response = await axios.get('/api/products');
            dispatch(slice.actions.setCurrentLp(res));
        }catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
    // console.log("ID" , id);
    // return async (dispatch) => {
    //     dispatch(slice.actions.startLoading());
    //     try {
    //         const response = await axios.get(`/lp/${id}`);
    //
    //         // console.log(`axios response ${id}` , response.data);
    //         if(response.data.success === false) {
    //             dispatch(slice.actions.hasError(response.data.data));
    //         }
    //
    //         dispatch(slice.actions.setPaymentsShop(response.data.data));
    //
    //     } catch (error) {
    //         dispatch(slice.actions.hasError(error));
    //     }
    // }
}

// export function setCurrentPayment(payment){
//     // console.log("CLICK" , shop);
//     return (dispatch) => dispatch(slice.actions.setCurrentPayment(payment));
// }
//
// export function setCurShop(shop){
//     // console.log("CLICK" , shop);
//     return (dispatch) => dispatch(slice.actions.setCurrentShop(shop));
// }

export function getQuizById(id){

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const res = _mockQuiz.find(item => item.id === id)
            dispatch(slice.actions.setCurrentQuiz(res));
        }catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function setCurrentLead(lead){
    return (dispatch) => {
        dispatch(slice.actions.setLead(lead));
    }
}


