

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";


import {useSelector} from "../../redux/store";
import {useLocales} from "../../locales";


MadeByBottom.propTypes = {
    onOpen: PropTypes.func ,
}

export default function MadeByBottom({onOpen}){
    const {t} = useLocales();
    const {  curShopId   } = useSelector((state) => state.startform);
    const { settings , isLoading } = useSelector((state) => state.shops);

// console.log("is" , isAdvancedDemo);
    const isNotFree = settings?.tariff === 'advanced' || settings?.tariff === 'demo'
        || settings?.tariff === 'standard' ;


    // console.log("ya" , yaMetrika);

    return (
        <>

            {!isNotFree &&
            <Button variant="text" onClick={onOpen} sx={{p:0.1}}>
                <Typography variant="subtitle2" component="div" sx={{color: 'text.disabled'}}>
                    {t('footer.work_to_notibot')}
                </Typography>
            </Button>
            }

        </>

    );
}
