import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// utils
import {fCurrency, fShortenNumber} from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
import List from "@mui/material/List";

import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItem from '@mui/material/ListItem/index';
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import {paths} from "../../../../routes/paths";

// ----------------------------------------------------------------------

export default function NotifyArticleList({ title, subheader, list, content_type , ...other }) {
    const navigate = useNavigate();
    const handleClick = (shopid , id) =>{
        // const nav = content_type === 'product' ? paths.dashboard.notify.open_trigger()
        //     : paths.product.article(shopid ,id);
        const nav =  paths.dashboard.notify.open_trigger(content_type , id , shopid);

        navigate(nav);
    }

    return (
        <>

        <Card {...other}>

            <List>
                {list.map((item, index) => (
                    <Item item={item} onHref={handleClick} type={content_type}/>
                ))}
            </List>

        </Card>
        </>
    );
}

NotifyArticleList.propTypes = {
    list: PropTypes.array,
    subheader: PropTypes.string,
    title: PropTypes.string,
    content_type: PropTypes.string ,
};

// ----------------------------------------------------------------------


//
function Item({ item, index , type , onHref }) {

  const handleClick = () => {
      onHref(item.shop_id , item.id)
  }
    return (
        <>
            <ListItem
                secondaryAction={
                    <IconButton edge="end" onClick={handleClick} sx={{p:0.5}}>
                        <Iconify icon="material-symbols:keyboard-arrow-right"/>
                    </IconButton>
                }
                sx={{p:1}}
            >
                {/* <ListItemButton onClick={()=>onHref(item.shop_id , item.id)} sx={{pl:1 , pr:1 }}> */}
                <ListItemAvatar onClick={handleClick} >
                    {item.images &&
                        <Avatar alt={item.title} src={item.images} sx={{borderRadius:1 , width: 48, height: 48}} />
                    }
                    {item?.icon &&
                        <Avatar sx={{borderRadius:1 , width: 48, height: 48}} >
                            <Iconify icon={item.icon} />
                        </Avatar>
                    }

                </ListItemAvatar>
                <ListItemText
                    primary={item.title}
                    onClick={handleClick}
                    secondary={
                        <>
                            {type === 'product' &&
                                <Stack direction="row" spacing={0.5} >
                                    {(!!item.price_sale>0) && (
                                        <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}>
                                            {fCurrency(item.price_sale)}
                                        </Box>
                                    )}

                                    <Box component="span" ><Typography color="primary">{fCurrency(item.price)}₽</Typography></Box>
                                </Stack>
                            }
                            {/* {type === 'article' && */}
                            {/*    <Typography variant="caption">&nbsp;</Typography> */}
                            {/* } */}


                        </>
                    }
                    sx={{mr:1}}
                />
                {/* </ListItemButton> */}
            </ListItem>
            <Divider sx={{ borderStyle: 'dashed' }} variant="inset" />
        </>
    );
}

Item.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    onHref: PropTypes.func ,
    type: PropTypes.string ,

};
