import {useCallback, useState} from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Button} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useDebounce} from "../../../../hooks/use-debounce";
import {useDispatch} from "../../../../redux/store";
import {getCategories, useGetProducts} from "../../../../api/product";
import NotifyArticleList from "./notify-article-list";
import NotifyFilterName from "../filter/notify-filter-name";
import {paths} from "../../../../routes/paths";
import Iconify from "../../../../components/iconify";
import {useLocales} from "../../../../locales";
import NotifyFilterType from "../filter/notify-filter-type";
import NotifyFilterCategory from "../filter/notify-filter-category";

const defaultFilters = {

    category: 'all',
    name: '' ,
    type: 'article' ,

};

NotifyArticleListView.propTypes = {
    shopid: PropTypes.string ,

}

export default function NotifyArticleListView({shopid }){

    // redux
    const dispatch = useDispatch();
    const { products, productsLoading, productsEmpty } = useGetProducts(shopid );
    const {t} = useLocales();

    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('featured');
    const debouncedQuery = useDebounce(searchQuery);
    const [filters, setFilters] = useState(defaultFilters);

    const handleFilters = useCallback((name, value) => {
        setFilters((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    const dataFiltered = applyFilter({
        inputData: products,
        filters,
        sortBy,
    });

    const canReset = !isEqual(defaultFilters, filters);

    const notFound = !dataFiltered.length && canReset;

    const handleSearch = useCallback((inputValue) => {
        setSearchQuery(inputValue);
    }, []);

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const prodCategOptions = getCategories(products.filter((product) => product.type === 'article'));

    return (
        <Container maxWidth='xs' sx={{px:1}}>
            <Stack spacing={1}>
                <Typography variant="caption">
                    {t('notify.auto.select_an_article')}
                </Typography>
                <Stack
                    spacing={1}
                    sx={{ mb: 1 }}
                >

                         <NotifyFilterName placeholder={t('system.search')}
                                           filterName={filters?.name}
                                           onFilterName={(e) => handleFilters('name' , e.target.value)}
                         />


                         {/* <Stack direction='column' spacing={1}> */}
                            <NotifyFilterCategory
                                filterCategory={[filters?.category]}
                                onFilterCategory={(val) => handleFilters('category' , val)}
                                optionsType={['-', ...prodCategOptions]}
                                onReset={handleResetFilters}
                            />


                            {/* {isFiltered && ( */}
                            {/*    <Button */}
                            {/*        variant="soft" */}
                            {/*        color="error" */}
                            {/*        onClick={handleClearAll} */}
                            {/*        startIcon={<Iconify icon="eva:trash-2-outline" />} */}
                            {/*    > */}
                            {/*        {`${t('system.clear')}`} */}
                            {/*    </Button> */}
                            {/* )} */}
                    {/*     </Stack> */}

                </Stack>
                <NotifyArticleList list={dataFiltered}
                                   content_type="article"
                                   title={t('system.articles')}
                                   subheader={t('notify.auto.the_opening_of_which_article')}/>

            </Stack>
        </Container>
    );
}

function applyFilter({ inputData, filters, sortBy , sortPrice }) {
    const {  category, type , name } = filters;

    // SORT BY
    // if (sortBy === 'featured') {
    //   inputData = orderBy(inputData, ['totalSold'], ['desc']);
    // }
    //
    // if (sortBy === 'newest') {
    //   inputData = orderBy(inputData, ['createdAt'], ['desc']);
    // }
    //
    // if (sortBy === 'priceDesc') {
    //   inputData = orderBy(inputData, ['price'], ['desc']);
    // }
    //
    // if (sortBy === 'priceAsc') {
    //   inputData = orderBy(inputData, ['price'], ['asc']);
    // }

    // FILTERS
    // if (gender.length) {
    //   inputData = inputData.filter((product) => gender.includes(product.gender));
    // }

    if (category !== 'all') {
        inputData = inputData.filter((product) => product.category === category);
    }

    if (type !== 'all') {
        inputData = inputData.filter((product) => product.type === type);
    }

    if (name) {
        inputData = inputData.filter(
            (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
    }

    // if (colors.length) {
    //   inputData = inputData.filter((product) =>
    //     product.colors.some((color) => colors.includes(color))
    //   );
    // }

    // if (min !== 0 || max !== 10000) {
    //   inputData = inputData.filter((product) => product.price >= min && product.price <= max);
    // }

    // if (rating) {
    //   inputData = inputData.filter((product) => {
    //     const convertRating = (value) => {
    //       if (value === 'up4Star') return 4;
    //       if (value === 'up3Star') return 3;
    //       if (value === 'up2Star') return 2;
    //       return 1;
    //     };
    //     return product.totalRatings > convertRating(rating);
    //   });
    // }

    // console.log("INp data" , inputData);

    return inputData;
}
