import {CardMedia} from "@mui/material";
import ReactPlayer from 'react-player/youtube'
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Iconify from "../../components/iconify";

import Markdown from "../../components/markdown";
import ResponsivePlayer from "../../components/player/responsive-player";
import {useSettingsContext} from "../../components/settings";
import {useSelector} from "../../redux/store";
import {useLocales} from "../../locales";
import Image from "../../components/image";
import {isTgLink, selfLink} from "../../utils/article-self-link";

const tg = window.Telegram?.WebApp;
export default function FooterOferta (){
    const {t} = useLocales();
    const {  curShopId   } = useSelector((state) => state.startform);
    const {settings , isLoading } = useSelector((state) => state.shops);

    const handleClick = (link) => {
        if(link){
            const objLink = selfLink(link , curShopId);
            // console.log("objLink" , objLink);
            if(objLink?.type === 'location'){
                if(isTgLink(objLink?.link)){
                    tg.openTelegramLink(objLink?.link);
                }else{
                    window.location.href = objLink?.link;
                }
            }else if(objLink?.type === 'router'){
                window.location.href = objLink?.link;
            }
        }
    }
    // const handleClick = () => {
    //     if(settings?.public_offer){
    //         const objLink = selfLink(settings.public_offer , curShopId);
    //         // console.log("objLink" , objLink);
    //         if(objLink?.type === 'location'){
    //             if(isTgLink(objLink?.link)){
    //                 tg.openTelegramLink(objLink?.link);
    //             }else{
    //                 window.location.href = objLink?.link;
    //             }
    //         }else if(objLink?.type === 'router'){
    //             window.location.href = objLink?.link;
    //         }
    //     }
    // }

    const isNullOffer = (offer) => {
        if(offer === null || offer === "")return true;
        return false;
    }
// console.log("settings?." , settings?.settings?.footer_links);
    const footerLinks = settings?.settings?.footer_links;
    return (
        <>
            {(!isLoading && !isNullOffer(footerLinks) ) &&
                <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Stack direction="row" spacing={0.5} divider={<Divider orientation="vertical" flexItem />}>
                        {footerLinks?.public_offer &&
                            <Typography variant="caption" sx={{mt:1 , textAlign: "center"}}>
                                <Link onClick={() => handleClick(footerLinks?.public_offer)}> {t('oferta')} </Link>
                            </Typography>
                        }
                        {footerLinks?.policy_link &&
                            <Typography variant="caption" sx={{mt:1 , textAlign: "center"}}>
                                <Link onClick={() => handleClick(footerLinks?.policy_link)}> {t('policy')} </Link>
                            </Typography>
                        }
                        {footerLinks?.requisites_link &&
                            <Typography variant="caption" sx={{mt:1 , textAlign: "center"}}>
                                <Link onClick={() => handleClick(footerLinks?.requisites_link)}> {t('requisites')} </Link>
                            </Typography>
                        }
                    </Stack>



                </>

            }

        </>
    );
}
