import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  // article: [],
  analyticsGame: [],
  // forms: [],
};

const slice = createSlice({
  name: 'analyticsGame',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.analyticsGame = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ANALYTICS_GAME
    getAnalyticsGameSuccess(state, action) {
      state.isLoading = false;
      state.analyticsGame = action.payload;
    },

   
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}


export function getAnalyticsGame(shopid) {

    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/games/${shopid}`);

        console.log("ANALYTICS_GAME" , response);
        dispatch(slice.actions.getAnalyticsGameSuccess(response.data.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
