import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import PropTypes from "prop-types";

const ErrorIndicator = (props) => {

    const {info} = props;
    return (
        <>
            <Alert severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                {info}
            </Alert>
        </>
    );
}

export default ErrorIndicator;

ErrorIndicator.propTypes = {
    info: PropTypes.string ,
}
