import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import {useCallback, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import { HEADER, NAV } from '../config-layout';
import {paths} from "../../routes/paths";
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';
import HeaderBackButton from "../_common/backbutton/header-back-button";
import {useRouter} from "../../routes/hooks";
import {useSelector} from "../../redux/store";
import LangPopover from "../_common/lang-popover";

// ----------------------------------------------------------------------

const tg = window.Telegram?.WebApp;
const userData = tg?.initDataUnsafe  ;

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const router = useRouter();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;



    const {  curShopId   } = useSelector((state) => state.startform);

    const handleBacklClick = useCallback(() => {
        // console.log("Location" , router.location());
        router.back()
    } , [router])

  const lct = router.location();
  const isGeneralPage = lct.pathname.includes('/dashboard/shop/');


  useEffect(() => {

    if(!isGeneralPage){
      // console.log("Show BTN  isGeneral" , isGeneralPage)
      tg.BackButton.show();
    }
    if(isGeneralPage){
      // dispatch(setFocusClick({})); // удалить инфу про фокус
      tg.BackButton.hide();
    }

    const modClose = () => {
      handleBacklClick();
      if(isGeneralPage){
        // console.log("click  isGeneral" , isGeneralPage)
        tg.BackButton.hide();
      }
    }

    tg?.onEvent('backButtonClicked', modClose)
    return () => {
      tg?.offEvent('backButtonClicked', modClose)
    }
  } , [isGeneralPage, handleBacklClick])

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

        {/* <Searchbar /> */}
        <HeaderBackButton onBack={handleBacklClick} isGeneral={isGeneralPage}/>

        <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
        >
         {/* <LangPopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        {/* <SettingsButton /> */}

        {/* <AccountPopover /> */}

            {!lgUp && (
                // <IconButton onClick={onOpenNav}>
                <IconButton onClick={()=>router.push(paths.dashboard.shop(curShopId ))}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}
        </Stack>
    </>
  );

  const hideHeader = !isGeneralPage && !userData?.user?.id ;

  return (
    <>

    {hideHeader &&
        <>
    <AppBar
        sx={{
            height: HEADER.H_MOBILE,
            zIndex: theme.zIndex.appBar + 1,
            ...bgBlur({
                color: theme.palette.background.default,
            }),
            transition: theme.transitions.create(['height'], {
                duration: theme.transitions.duration.shorter,
            }),
            ...(lgUp && {
                width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
                height: HEADER.H_DESKTOP,
                ...(offsetTop && {
                    height: HEADER.H_DESKTOP_OFFSET,
                }),
                ...(isNavHorizontal && {
                    width: 1,
                    bgcolor: 'background.default',
                    height: HEADER.H_DESKTOP_OFFSET,
                    borderBottom: `dashed 1px ${theme.palette.divider}`,
                }),
                ...(isNavMini && {
                    width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                }),
            }),
        }}
    >
        <Toolbar
            sx={{
                height: 1,
                px: { lg: 5 },
            }}
        >
            {renderContent}

        </Toolbar>

    </AppBar>
        </>
    }

    </>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
